import React, { useState } from 'react'
import { withI18n } from '~/hocs'
import { assetURL, getAvatar, redirectLogin, redirectSignUp, redirectSignUpCreator } from '~/utils'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import Link from 'next/link'
import { routes } from '~/router'
import config from '~/config'
import DropdownApp from '../home/DropdownApp'
import { Button, Dropdown, DropdownButton, NavDropdown, NavLink, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { signIn, signOut } from 'next-auth/react'


const HeaderRight: NextPage<AppCommonProps> = ({ t, lng, isPremium, isAuthen, nextAuthStatus, nextAuthSession, profile }) => {
    const [borderColor, setBorderColor] = useState('border-3C9275'); // Trạng thái mặc định là màu xanh

    const toggleBorderColor = () => {
        setBorderColor(prevColor => prevColor === 'border-3C9275' ? 'border-F8F9FA' : 'border-3C9275');
    };
        return (
        <div className="d-flex header-right-big-screen align-items-center nav-btns">
            {
                !isAuthen || nextAuthStatus === 'authenticated'
                    ? (
                        // Not logged in as downloader
                        nextAuthStatus === 'authenticated'
                            ? (
                                // Logged in as creator
                                <>
                                    <DropdownApp />
                                    <div className="dropdown-logined-creator d-flex align-items-center">
                                        <NavDropdown
                                            title={
                                                <>
                                                    <img className="d-block mw-100 mx-auto lazy lazy-loading avatar-creator-image" width="40" alt="Avatar"
                                                        src={nextAuthSession.user?.avatar?.substring(0, 8) == 'property' ? `${config.assetPrefix}/${nextAuthSession.user?.avatar}` : (nextAuthSession.user?.avatar ? nextAuthSession.user?.avatar : `${process.env.ASSET_PREFIX}/asset/image/creator/avatar/0-0${1}.jpg`)} />
                                                    <div className="ml-2">
                                                        <p className="m-0">クリエイター</p>
                                                        <span className="d-block">{nextAuthSession.user?.nickname
                                                            ? <>{nextAuthSession.user?.nickname.length < 20 ? nextAuthSession.user?.nickname : nextAuthSession.user?.nickname.substring(0, 20) + '...'}さん</>
                                                            : <>{nextAuthSession.user?.email.substring(0, 1) + '**********' + nextAuthSession.user?.email.substring(nextAuthSession.user?.email.length - 1, nextAuthSession.user?.email.length)}さん</>}</span>
                                                    </div>
                                                </>
                                            }
                                            id="basic-nav-dropdown--"
                                            alignRight
                                            className="nav-item-profile hover-blue"
                                        >
                                            <NavDropdown.Item as={Button} className="p-0 border-bottom">
                                                <Link href={routes.creator.dashboard.href} locale={lng}>
                                                    <a className="d-block px-3 py-2 w-100 dropdown-item-logined">{t('my_page')}</a>
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as={Button} className="px-3 py-2 w-100 dropdown-item-logined" onClick={() => signOut({ callbackUrl: routes.home.href })}>{t('logout')}</NavDropdown.Item>
                                        </NavDropdown>
                                    </div>
                                </>
                            )
                            : (
                                // Not logged in
                                <>
                                    <div className='button-login-mobile'>
                                        <DropdownButton
                                            id={'dropdown-button-drop-start'}
                                            // drop="start"
                                            className='mr-1 button-login-mobile-register'
                                            title={<FontAwesomeIcon icon={faUserPlus} />}
                                        >
                                            <Dropdown.Item className="mr-3" variant="outline-primary" onClick={() => redirectSignUp(lng)}>{t('downloader_signup')}</Dropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <Dropdown.Item className="mr-3" variant="outline-primary" onClick={() => redirectSignUpCreator(lng)}>{t('creator_signup')}</Dropdown.Item>
                                                    : (<></>)
                                            }
                                        </DropdownButton>
                                        <DropdownButton
                                            id={'dropdown-button-drop-start'}
                                            // drop="start"
                                            className='button-login-mobile-login'
                                            title={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                                        >
                                            <Dropdown.Item onClick={() => redirectLogin(lng)}>{t('download_user')}</Dropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <Dropdown.Item onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</Dropdown.Item>
                                                    : (<></>)
                                            }
                                        </DropdownButton>
                                    </div>
                                    <div className='d-none d-xl-flex align-items-center'>
                                        {
                                            config.isEnableCreator
                                                ? <a className="mr-3 creator-signup-button" onClick={() => redirectSignUpCreator(lng)}>
                                                    {/* <i className="fa-solid fa-camera" style={{ fontSize: "14px" }}></i><span className="ml-1">{t('creator_signup')}</span> */}
                                                    <OverlayTrigger
                                                        key={'top'}
                                                        placement={'bottom'}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`} style={{fontSize: "13px"}}>
                                                            {t('creator_signup')}
                                                            </Tooltip>
                                                        }
                                                        >
                                                        <span>
                                                            <i className="fa-solid fa-camera" style={{ fontSize: "14px" }}></i><span className="ml-1">動画投稿する</span>
                                                        </span>
                                                    </OverlayTrigger>
                                                </a>
                                                : (<></>)
                                        }
                                        <NavLink className="nav-link-premium ac-btn mr-2 button-premium" href={config.premiumUrl} target="_blank">
                                    <img className="mw-100 mr-2 lazy loaded " alt="プレミアム会員登録" width="20" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png"
                                        data-ll-status="loaded" />{t('go_premium')}
                                </NavLink>
                                        <Button className="mr-3 downloader-signup-button" variant="outline-primary" onClick={() => redirectSignUp(lng)}>{t('downloader_signup')}</Button>
                                        <NavDropdown
                                            title={<><Button><i className="fas fa-sign-in-alt" /> &nbsp; {t('login')}</Button></>}
                                            id="signin-nav-dropdown"
                                        >
                                            <NavDropdown.Item className="dropdown-item-login" onClick={() => redirectLogin(lng)}>{t('download_user')}</NavDropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <NavDropdown.Item className="dropdown-item-login" onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</NavDropdown.Item>
                                                    : (<></>)
                                            }
                                        </NavDropdown>
                                        <DropdownApp />
                                    </div>
                                    <div className='d-none d-lg-flex d-xl-none align-items-center'>
                                        <Button className="mr-3 downloader-signup-button" variant="outline-primary" onClick={() => redirectSignUp(lng)}>{t('downloader_signup_short')}</Button>
                                        {
                                            config.isEnableCreator
                                                // ? <Link href={`${routes.creator.reg.href}`} locale={lng}><a className="btn btn-outline-primary mr-3" >{t('creator_signup')}</a></Link>
                                                ? <Button className="mr-3 downloader-signup-button" variant="outline-primary" onClick={() => redirectSignUpCreator(lng)}>{t('creator_signup')}</Button>
                                                : (<></>)
                                        }
                                        <NavDropdown
                                            title={<><Button><i className="fas fa-sign-in-alt" /> &nbsp; {t('login')}</Button></>}
                                            id="signin-nav-dropdown">
                                            <NavDropdown.Item className="dropdown-item-login" onClick={() => redirectLogin(lng)}>{t('download_user')}</NavDropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <NavDropdown.Item className="dropdown-item-login" onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</NavDropdown.Item>
                                                    : (<></>)
                                            }
                                        </NavDropdown>
                                        <DropdownApp />
                                    </div>
                                    <div className='d-none d-sm-flex d-xl-none d-lg-none align-items-center'>
                                        <NavDropdown
                                            title={<><Button onClick={() => {
                                                toggleBorderColor();
                                              }} className={`btn-md-register ${borderColor}`} variant="outline-primary">{t('free_signup')}</Button></>}
                                            id="signin-nav-dropdown">
                                            <NavDropdown.Item className="dropdown-item-login" onClick={() => redirectSignUp(lng)}>{t('downloader_signup')}</NavDropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <NavDropdown.Item className="dropdown-item-login" onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator_signup')}</NavDropdown.Item>
                                                    : (<></>)
                                            }
                                            <NavDropdown.Item className="dropdown-item-login" onClick={() => window.open(config.premiumUrl, '_blank')}>
                                            <span className='d-flex align-items-center'>{t('go_premium')}<img className="mw-100 ml-2 lazy loaded " alt="プレミアム会員登録" width="20" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png"
                                                        data-ll-status="loaded" /></span>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown
                                            title={<><Button className='ml-2'>{t('login')}</Button></>}
                                            id="signin-nav-dropdown">
                                            <NavDropdown.Item className="dropdown-item-login" onClick={() => redirectLogin(lng)}>{t('download_user')}</NavDropdown.Item>
                                            {
                                                config.isEnableCreator
                                                    ? <NavDropdown.Item className="dropdown-item-login" onClick={() => signIn(undefined, { callbackUrl: routes.creator.dashboard.href })}>{t('creator')}</NavDropdown.Item>
                                                    : (<></>)
                                            }
                                        </NavDropdown>
                                        <DropdownApp />
                                    </div>
                                </>
                            )
                    )
                    : (
                        // Logged in as downloader
                        <>
                            {
                                !isPremium && <NavLink className="nav-link-premium ac-btn mr-2 button-premium" href={config.premiumUrl} target="_blank">
                                    <img className="mw-100 mr-2 lazy loaded " alt="プレミアム会員登録" width="20" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png"
                                        data-ll-status="loaded" />{t('go_premium')}
                                </NavLink>
                            }
                            <DropdownApp />
                            <div className="dropdown-menu-profile">
                                <NavDropdown
                                    title={
                                        <>
                                            <img src={getAvatar(profile)} alt="avatar" width="40" />
                                            <div className="ml-2">
                                                {
                                                    isPremium
                                                        ? <span className="d-flex align-items-center">
                                                            <img className="mr-1" src={assetURL('asset/image/crown.png')} width="20" />
                                                            <span style={{ marginTop: 4 }}>
                                                                {profile?.premium_info?.role ? (profile?.premium_info?.role?.id === 1 ? t('premium_contractor') : t('premium_member')) : t('premium_individual')}
                                                            </span>
                                                        </span>
                                                        : <span className="d-block">{t('free_members')}</span>
                                                }
                                                <span className="d-block">
                                                    {
                                                        profile.nick_name
                                                            ? <>{profile?.nick_name.length < 20 ? profile?.nick_name : profile?.nick_name.substring(0, 20) + '...'}さん</>
                                                            : <>{profile?.email.substring(0, 1) + '**********' + profile?.email.substring(profile?.email.length - 1, profile?.email.length)}さん</>
                                                    }
                                                </span>
                                            </div>
                                        </>
                                    }
                                    id="basic-nav-dropdown--"
                                    alignRight
                                    className="nav-item-profile hover-blue p-0"
                                >
                                    <div className="dropdown-item-text border-bottom">
                                        {profile.nick_name && <span className="d-block">{profile?.nick_name.length < 20 ? profile?.nick_name : profile?.nick_name.substring(0, 20) + '...'}さん</span>}
                                        <span className="font-weight-bold">{profile?.email.substring(0, 1) + '**********' + profile?.email.substring(profile?.email.length - 1, profile?.email.length)}</span>
                                    </div>
                                    <NavDropdown.Item as={Button} className="p-0">
                                        <Link href={routes.user.dashboard.href} locale={lng}>
                                            <a className="d-block px-3 py-2 w-100 dropdown-item-logined border-bottom">{t('my_page')}</a>
                                        </Link>
                                    </NavDropdown.Item>
                                    {
                                        isPremium &&
                                        <>
                                            <NavDropdown.Item as={Button} className="p-0">
                                                <Link href={routes.user.downloads.href} locale={lng}>
                                                    <a className="d-block px-4 py-2 w-100">
                                                        <i className="fa fa-download mr-2" aria-hidden="true" />ダウンロード履歴
                                                    </a>
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as={Button} className="p-0">
                                                <Link href={routes.user.presents.href} locale={lng}>
                                                    <a className="d-block px-4 py-2 w-100">
                                                        プレミアム会員特典
                                                    </a>
                                                </Link>
                                            </NavDropdown.Item>
                                        </>
                                    }
                                    <NavDropdown.Item as={Button} className="p-0">
                                        <Link href="https://help.freebie-ac.jp/" locale={lng}>
                                            <a className="d-block px-3 py-2 w-100 dropdown-item-logined border-bottom" target="_blank">よくある質問</a>
                                        </Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="dropdown-item-logined border-bottom px-3 py-2 w-100" as={Button} onClick={() => { location.href = `/api/auth/signout?redirect=${window.location.href}` }}>{t('logout')}</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </>
                    )
            }
        </div>
    )
}

export default withI18n(HeaderRight)